/* ion-menu-button */
ion-menu-button {
  color: var(--ion-color-primary);
}

/* ion-button */
ion-button.zero-padding {
  width: unset !important;
  height: unset !important;
  --padding-top: 0 !important;
  --padding-end: 0 !important;
  --padding-bottom: 0 !important;
  --padding-start: 0 !important;
}

ion-input.compact {
  min-height: 32px !important;
}

/* ion-select */
ion-select:not(.legacy-select) {
  min-height: 32px;
}
ion-select {
  height: 56px;
}

/* ion-toggle */
ion-toggle {
  height: 56px;
}

/* ion-popover */
ion-popover.node-type-selector {
  --max-width: 275px;
  --width: 275px;
}

/* ion-toolbar */
ion-toolbar.compact {
  --min-height: 32px;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
}
