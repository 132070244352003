/* Uncomment following lines to make tailwind available for project */
@tailwind base;
@tailwind components;
@tailwind utilities;


/* Container */
.container {
  width: 100%;
  height: 100% fit-content;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

.container .logo-link {
  width: 100%;
}

.container .logo-image {
  width: 100%;
  max-width: 250px;
  margin: 0 auto 0 auto;
}

.centered {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}

.form-group-section {
  // margin-top: 32px;
  margin-bottom: 32px;
  background: rgba(var(--ion-color-light-rgb), 0.3);
  box-shadow: 0px 0px 10px rgba(var(--ion-color-medium-rgb), 0.4);
  padding: 5px 5px 15px 5px;
  border-radius: 10px;

}


/* Generic UI Custom Style */
.gui-search-bar form input {
  background: #fff;
}
.gui-options-list .gui-option {
  color: #222;
}
.gui-text-view {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}




/* Size */
.width-100 { width: 100%; }
.width-50 { width: 50%; }
